function initConverterValues() {
  if ($('.wcdp-choose-donation').length == 0) {
    return false;
  }

  const valores = $('#wcdp_amount > li:not(:last-child)');
  $(valores).each(function () {
    const label = $(this).find('label');
    const valor_inicial = label.text().replace('R$', '');
    const valor_convertido = (valor_inicial / 1000).toFixed(3);
    label.text('R$' + valor_convertido + ',00');
  });
}

export { initConverterValues }