function initCheckoutPage() {
  if ($('.woocommerce-order-received').length == 0) {
    return false;
  }

  if ($('.iugu-pix-qrcode-div').length == 0) {
    $('.wcdp-donation-received-heading').remove();
    $('.woocommerce-order-details').empty();
    $('.woocommerce-order-details').addClass('woocommerce-order-details-pantera');
  }
}

export { initCheckoutPage }