import { initImagens, initCarrossel } from '../modules/slider';
import { initImagemGrid } from '../modules/imagem_doacao';
import { initBandeira } from '../modules/bandeira';
import { initModal } from '../modules/modal';
import { initVideo } from '../modules/video';
import { initHeader, initMobileMenu } from '../modules/header';
import { initConverterValues } from '../modules/converter';
import { initTourVirtual } from '../modules/tabs';
import { initCheckoutPage } from '../modules/checkout';

export default {
  init() {
  },
  finalize() {
    initImagens();
    initCarrossel();
    initImagemGrid();
    initBandeira();
    initModal();
    initVideo();
    initHeader();
    initMobileMenu();
    initConverterValues();
    initTourVirtual();
    initCheckoutPage();
  },
};
