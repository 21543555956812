function initTourVirtual() {
  if ($('.tabs-module').length == 0) {
    return false;
  }

  $(document).ready(function () {
    $('.tabs-module-tab-item').each(function () {
      if ($(this).data('index') == 0) {
        $(this).addClass('tab-active');
      }
    });

    $('.tabs-module-tab-item').on('click', function () {
      var tabId = $(this).data('index');
      $('.tabs-module-tab-item').not(this).removeClass('tab-active');
      $(this).addClass('tab-active');
      $('.tabs-module-box-item').removeClass('show');
      $('.tabs-module-box #tab-' + tabId).addClass('show');
    });
  });

}

export { initTourVirtual }