function initMobileMenu() {
  const menu_open = $('#btn-open-menu');
  const nav = $('.header-module-navigation');
  const hero_homepage = $('.hero-module-homepage');
  const header = $('.header-module');
  const body = $('body');

  if (window.matchMedia('(max-width: 991px)').matches) {
    menu_open.on('click', function () {
      $(this).toggleClass('active');
      nav.toggleClass('active');
      hero_homepage.toggleClass('menu-opened');
      header.toggleClass('menu-opened');
      body.toggleClass('body-hidden');
    });
  }
}

function initHeader() {
  const header = $('.header-module');

  $(window).on('scroll', function () {
    const scrollTop = $(window).scrollTop();
    if (scrollTop > 400) {
      header.addClass('banner-sticky');
    } else {
      header.removeClass('banner-sticky');
    }
  });
}

export { initMobileMenu, initHeader }