function initModal() {
  if ($('.hero-module-video').length == 0) {
    return false;
  }

  $('#hero-video-player').on('click', function () {
    var videoId = $(this).data('video');
    $('.hero-module-video-modal .modal-body').html('<div class="video-responsive"><iframe width="640" height="360" src="' + videoId + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>');
    $('.hero-module-video-modal').modal('toggle');
  });

  $('.hero-module-video-modal').on('hidden.bs.modal', function () {
    $('.hero-module-video-modal .modal-body').html('');
  })
}

export { initModal }