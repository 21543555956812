function initBandeira() {
  const n = localStorage.getItem('language');
  if (n === null) {
    if ($('.texto-ingles').hasClass('language-active')) {
      localStorage.setItem('language', 'ingles');
      doacaoTextoIngles();
    } else {
      localStorage.setItem('language', 'portugues');
      doacaoTextoPortugues();
    }
  } else {
    if (n === 'ingles') {
      $('.texto-ingles').addClass('language-active');
      $('.texto-portugues').removeClass('language-active');
      localStorage.setItem('language', 'ingles');
      doacaoTextoIngles();
    } else {
      $('.texto-ingles').removeClass('language-active');
      $('.texto-portugues').addClass('language-active');
      localStorage.setItem('language', 'portugues');
      doacaoTextoPortugues();
    }
  }

  $('.header-module-bandeira').click(function () {
    if ($('.texto-ingles').hasClass('language-active')) {
      $('.texto-ingles').removeClass('language-active');
      $('.texto-portugues').addClass('language-active');
      localStorage.setItem('language', 'portugues');
      doacaoTextoPortugues();
    } else {
      $('.texto-ingles').addClass('language-active');
      $('.texto-portugues').removeClass('language-active');
      localStorage.setItem('language', 'ingles');
      doacaoTextoIngles();
    }
  });
}

function doacaoTextoIngles() {
  $('label.wcdp-variation-heading').text('Amount*');
  $('#wcdp_other').text('Other');
  $('button.wcdp-button').text('donate');
  $('.woocommerce-billing-fields > h3').text('Donor details');
  $('#billing_first_name_field > label').html('NAME <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_persontype_field > label').text('YOU ARE:');
  $('#billing_country_field > label').text('COUNTRY');
  $('#billing_state_field > label').html('STATE <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_city_field > label').html('CITY <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_address_1_field > label').html('ADDRESS <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_phone_field > label').html('PHONE <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_occupation_field > label').html('YOU ARE (check all that apply) <abbr class="required" title="obrigatório">*</abbr>');


  $('#billing_occupation_field .woocommerce-multicheckbox-wrapper label').each(function (index) {
    const label = $(this);
    const input = label.find('input[type="checkbox"]');
    if (index == 0) {
      label.html('Lower School family');
    }
    if (index == 1) {
      label.html('Upper School family');
    }
    if (index == 2) {
      label.html('Board members');
    }
    if (index == 3) {
      label.html('Employee');
    }
    if (index == 4) {
      label.html('Corporate');
    }
    if (index == 5) {
      label.html('Alumni');
    }
    if (index == 6) {
      label.html('Other');
    }
    label.prepend(input);
  });

  $('#billing_reconhecimento_field > label').html('WE WOULD LIKE TO CELEBRATE OUR COMMUNITY SUPPORT. <span>Please indicate below if you would like to be recognized:</span> <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_reconhecimento_field > .woocommerce-input-wrapper > label[for=billing_reconhecimento_yes]')
    .html('YES, I would like my donation recognized with a plaque and in the annual report. <span>(Only donations of R$1.000 or more will be recognized with a plaque. If this is your case, we will reach out with further details about the plaque)</span>');
  $('#billing_reconhecimento_field > .woocommerce-input-wrapper > label[for=billing_reconhecimento_No]')
    .text('NO, I would prefer my donation to be anonymous.');

  const terms_label = $('#billing_termos_field > .woocommerce-input-wrapper > label');
  const terms_input = terms_label.find('input[type="checkbox"]');
  terms_label.html('Accept the terms and conditions <abbr class="required" title="obrigatório">*</abbr>');
  terms_label.prepend(terms_input);

  $('.woocommerce-checkout #order_review .woocommerce-checkout-review-order-table tfoot tr th').text('Donation Amount');
  $('#payment button#place_order').text('donate now');

  if ($('.iugu-pix-qrcode-div').length > 0) {
    $('.woocommerce-order-details').empty();
    var classList = $('body').attr('class').split(/\s+/);
    var pagina_conclusao;
    $.each(classList, function (index, item) {
      if (item.match('^pagina-conclusao-')) {
        pagina_conclusao = item.split('pagina-conclusao-')[1];
      }
    });
    $('.woocommerce-order-details').append('<p>After completing the payment at you bank, <a href="https://earjraisingpanthers.com.br/temp/' + pagina_conclusao + '">click here</a></p>');
    $('#iugu_pix_qrcode_text_button').text('Copy code');
  }
}

function doacaoTextoPortugues() {
  $('label.wcdp-variation-heading').text('Valor*');
  $('#wcdp_other').text('Outro');
  $('button.wcdp-button').text('doar');
  $('.woocommerce-billing-fields > h3').text('Detalhes do doador');
  $('#billing_first_name_field > label').html('NOME <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_persontype_field > label').text('VOCÊ É:');
  $('#billing_country_field > label').text('PAÍS');
  $('#billing_state_field > label').html('ESTADO <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_city_field > label').html('CIDADE <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_address_1_field > label').html('ENDEREÇO <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_phone_field > label').html('TELEFONE <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_occupation_field > label').html('VOCÊ É: (marque todas as opções que se aplicam) <abbr class="required" title="obrigatório">*</abbr>');

  $('#billing_occupation_field .woocommerce-multicheckbox-wrapper label').each(function (index) {
    const label = $(this);
    const input = label.find('input[type="checkbox"]');
    if (index == 0) {
      label.html('Família do Lower School');
    }
    if (index == 1) {
      label.html('Família do Upper School');
    }
    if (index == 2) {
      label.html('Membro do Conselho');
    }
    if (index == 3) {
      label.html('Funcionário');
    }
    if (index == 4) {
      label.html('Corporativo');
    }
    if (index == 5) {
      label.html('Alumni');
    }
    if (index == 6) {
      label.html('Outro');
    }
    label.prepend(input);
  });

  $('#billing_reconhecimento_field > label').html('Gostaríamos de celebrar o apoio da nossa comunidade. <span>Por favor, indique abaixo se deseja ser reconhecido:</span>  <abbr class="required" title="obrigatório">*</abbr>');
  $('#billing_reconhecimento_field > .woocommerce-input-wrapper > label[for=billing_reconhecimento_yes]')
    .html('Sim, quero que minha doação seja reconhecida com uma placa e seja mencionada no annual report. <span>(Somente doações de R$ 1.000,00 ou acima serão reconhecidas com uma placa. Se for o seu caso, entraremos em contato com informações adicionais sobre a placa.)</span>');
  $('#billing_reconhecimento_field > .woocommerce-input-wrapper > label[for=billing_reconhecimento_No]')
    .text('Não, prefiro que minha contribuição fique anônima.');

  const terms_label = $('#billing_termos_field > .woocommerce-input-wrapper > label');
  const terms_input = terms_label.find('input[type="checkbox"]');
  terms_label.html('Aceito os termos e as condições  <abbr class="required" title="obrigatório">*</abbr>');
  terms_label.prepend(terms_input);

  $('.woocommerce-checkout #order_review .woocommerce-checkout-review-order-table tfoot tr th').text('Valor da doação');
  $('#payment button#place_order').text('doe agora');

  if ($('.iugu-pix-qrcode-div').length > 0) {
    $('.woocommerce-order-details').empty();
    var classList = $('body').attr('class').split(/\s+/);
    var pagina_conclusao;
    $.each(classList, function (index, item) {
      if (item.match('^pagina-conclusao-')) {
        pagina_conclusao = item.split('pagina-conclusao-')[1];
      }
    });
    $('.woocommerce-order-details').append('<p>Após concluir o pagamento no seu banco, <a href="https://earjraisingpanthers.com.br/temp/' + pagina_conclusao + '">clique aqui</a></p>');
    $('#iugu_pix_qrcode_text_button').text('Copiar código');
  }
}

export { initBandeira }