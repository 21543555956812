function initImagemGrid() {
  if ($('.imagem-doacao-module').length == 0) {
    return false;
  }

  var numbersArray = [208, 743, 351, 503, 192, 155, 911, 375, 293, 904, 692, 120, 725, 506, 687, 733, 880, 818, 356, 951, 390, 496, 730, 219, 142, 901, 298, 598, 928,
    639, 956, 799, 714, 158, 306, 450, 484, 874, 329, 225, 581, 482, 109, 152, 526, 368, 334, 547, 363, 962, 13, 497, 426, 971, 348, 565, 614, 564, 599, 372, 376, 916,
    113, 555, 370, 708, 206, 19, 69, 218, 788, 31, 529, 477, 575, 53, 802, 892, 621, 594, 987, 184, 597, 26, 161, 618, 615, 249, 325, 177, 138, 783, 259, 898, 185, 139, 762,
    457, 891, 757, 894, 393, 910, 101, 728, 328, 560, 336, 162, 694, 256, 661, 446, 963, 948, 141, 912, 17, 265, 932, 195, 312, 753, 11, 124, 702, 83, 832, 37, 409, 51, 693,
    988, 440, 586, 819, 601, 990, 432, 752, 695, 713, 592, 297, 958, 269, 49, 955, 721, 977, 994, 722, 275, 337, 105, 462, 404, 24, 913, 573, 729, 9, 98, 88, 846, 314, 900,
    957, 413, 23, 793, 952, 313, 857, 745, 3, 746, 367, 720, 965, 243, 443, 931, 118, 924, 509, 111, 563, 998, 853, 29, 55, 617, 291, 511, 94, 823, 378, 215, 44, 641, 542, 311,
    779, 697, 458, 905, 964, 850, 778, 535, 342, 888, 709, 224, 660, 943, 517, 681, 882, 435, 755, 86, 646, 136, 643, 299, 121, 70, 821, 889, 767, 632, 207, 449, 213, 99, 523, 170,
    899, 411, 914, 437, 472, 340, 10, 996, 983, 45, 944, 795, 338, 281, 929, 785, 593, 485, 127, 986, 842, 972, 798, 744, 282, 859, 54, 167, 62, 474, 806, 277, 18, 562, 665, 468, 960,
    180, 174, 854, 525, 602, 633, 254, 518, 566, 843, 34, 25, 872, 355, 668, 481, 373, 839, 545, 201, 772, 332, 100, 398, 39, 344, 145, 556, 704, 310, 361, 341, 533, 14, 103, 1,
    724, 321, 682, 765, 851, 895, 276, 123, 15, 499, 616, 407, 999, 626, 308, 438, 172, 470, 353, 464, 984, 222, 295, 868, 345, 659, 399, 42, 656, 703, 933, 223, 558, 680, 726, 688,
    476, 301, 576, 410, 731, 808, 110, 451, 613, 143, 969, 906, 352, 648, 422, 93, 735, 554, 740, 147, 85, 6, 419, 579, 221, 881, 303, 568, 513, 706, 89, 87, 690, 790, 780, 997, 835,
    168, 36, 572, 635, 159, 937, 197, 867, 666, 232, 454, 204, 543, 441, 966, 890, 236, 8, 776, 452, 16, 385, 91, 300, 283, 515, 507, 686, 30, 885, 475, 114, 140, 608, 600, 605, 190, 429,
    318, 186, 789, 596, 133, 493, 448, 377, 447, 531, 803, 357, 907, 679, 700, 625, 387, 974, 362, 181, 326, 549, 505, 816, 92, 805, 920, 144, 320, 122, 209, 717, 707, 478, 384, 747, 119,
    427, 544, 813, 431, 104, 833, 845, 106, 538, 268, 992, 884, 502, 585, 252, 578, 734, 488, 855, 764, 490, 288, 630, 647, 260, 768, 77, 991, 976, 414, 436, 978, 580, 915, 198, 838, 557, 383,
    216, 273, 942, 131, 685, 1000, 973, 736, 674, 278, 651, 220, 627, 4, 227, 412, 771, 156, 210, 182, 153, 993, 583, 12, 212, 827, 587, 922, 148, 840, 323, 21, 242, 214, 604, 319, 78, 82, 226,
    559, 494, 512, 280, 240, 631, 676, 921, 570, 897, 52, 400, 662, 739, 732, 194, 574, 896, 307, 770, 324, 333, 61, 244, 588, 691, 801, 750, 536, 64, 638, 870, 649, 834, 510, 689, 953, 263,
    820, 949, 381, 56, 261, 508, 235, 445, 620, 41, 591, 590, 858, 672, 374, 766, 611, 128, 634, 552, 287, 239, 395, 230, 164, 137, 65, 2, 274, 75, 35, 173, 469, 20, 653, 284, 492, 828, 392,
    360, 487, 540, 267, 873, 50, 781, 959, 423, 117, 652, 658, 683, 761, 909, 71, 60, 456, 327, 316, 784, 165, 108, 84, 245, 415, 710, 354, 985, 394, 498, 886, 347, 862, 279, 636, 866, 809, 231,
    527, 233, 402, 417, 787, 76, 759, 304, 371, 723, 520, 584, 465, 151, 908, 934, 926, 46, 903, 203, 386, 861, 595, 791, 671, 640, 849, 589, 673, 238, 712, 74, 774, 619, 134, 754, 830, 160, 879, 667,
    995, 654, 183, 534, 669, 607, 741, 272, 550, 553, 366, 935, 48, 228, 970, 479, 848, 684, 250, 856, 258, 130, 379, 406, 290, 418, 408, 102, 271, 824, 270, 5, 530, 967, 655, 657, 887, 453, 63, 837,
    773, 330, 262, 72, 73, 528, 810, 860, 331, 461, 711, 189, 715, 191, 40, 763, 947, 433, 382, 876, 248, 380, 292, 623, 609, 57, 459, 401, 546, 758, 425, 782, 514, 539, 541, 467, 343, 786, 178,
    927, 237, 491, 349, 852, 27, 200, 698, 519, 936, 388, 466, 826, 878, 365, 829, 196, 421, 677, 577, 286, 716, 129, 979, 815, 115, 567, 217, 663, 188, 442, 650, 317,
    582, 199, 246, 149, 524, 43, 642, 126, 738, 59, 150, 751, 742, 7, 610, 769, 664, 940, 777, 96, 202, 875, 847, 760, 551, 825, 444, 629, 863, 358, 794,
    945, 95, 396, 163, 309, 305, 460, 253, 391, 537, 622, 285, 80, 504, 486, 179, 918, 359, 637, 211, 397, 58, 521, 869, 187, 817, 644, 804, 797, 678, 938, 364, 883,
    97, 205, 489, 675, 171, 339, 424, 463, 941, 146, 294, 701, 532, 831, 975, 455, 865, 38, 193, 264, 603, 893, 705, 864, 405, 756, 917, 420, 166, 335, 480, 430, 737, 116, 877, 471,
    718, 628, 796, 748, 47, 548, 90, 841, 902, 500, 483, 800, 954, 812, 950, 919, 792, 989, 836, 561, 157, 719, 112, 807, 645, 473, 501, 946, 822, 439, 257, 569, 79, 961, 255,
    495, 234, 315, 68, 369, 925, 33, 107, 980, 81, 66, 939, 389, 844, 241, 624, 403, 251, 923, 302, 968, 28, 982, 428, 176, 350, 727, 289, 522, 571, 296, 606, 135, 175, 67, 811, 346, 247,
    125, 266, 670, 434, 322, 749, 154, 696, 814, 930, 612, 132, 229, 32, 981, 871, 416, 699, 775, 516, 22, 169];

  const finalNumber = $('#imagem-doacao-grid').data('number');
  const openedNumbers = numbersArray.slice(0, finalNumber);

  if (window.matchMedia('(max-width: 991px)').matches) {
    var a = 25;
    var b = 40;
    var c = '<table cellspacing="0" border="0" cellpadding="0" class="grxd">';
    for (var j = 1; j <= (a * b); j++) {
      c += (j == 1 ? '<tr>' : '');
      c += '<td class="number ';
      if (jQuery.inArray(j, openedNumbers) !== -1) {
        c += 'number_open ';
      }
      c += 'number_' + j + '"></td > ';
      if (j == (a * b)) {
        c += '</tr>';
      } else {
        c += (j % 25 === 0 ? '</tr><tr>' : '');
      }
    }
    c += '</table>';
    $('#imagem-doacao-grid').html(c);
  } else {
    var x = 40;
    var y = 25;
    var t = '<table cellspacing="0" border="0" cellpadding="0" class="grxd">';
    for (var i = 1; i <= (x * y); i++) {
      t += (i == 1 ? '<tr>' : '');
      t += '<td class="number ';
      if (jQuery.inArray(i, openedNumbers) !== -1) {
        t += 'number_open ';
      }
      t += 'number_' + i + '"></td > ';
      if (i == (x * y)) {
        t += '</tr>';
      } else {
        t += (i % 40 === 0 ? '</tr><tr>' : '');
      }
    }
    t += '</table>';
    $('#imagem-doacao-grid').html(t);
  }
}

export { initImagemGrid }
